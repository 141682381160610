import * as React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {Test} from './pages/test';
import { Button } from '@mui/material';
import { AuthConsumer, AuthContext, AuthProvider } from './contexts/AuthContext';
import { Auth } from './pages/auth';
import { Token } from './pages/token';
import { DS } from './pages/ds';
import { SplashScreen } from './pages/splash-screen';
import NotFound from './pages/404';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isLoggedIn, logout } = React.useContext(AuthContext);
 
  console.log('isLoggedIn', isLoggedIn);
  
  //  const test = localStorage.getItem("@token");
  let location = useLocation();
  if(!isLoggedIn){
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  
  return children;
}


export default function App() {
  const [user, setUser] = React.useState(null);

  return (
    <AuthProvider>
    <BrowserRouter>
            <AuthConsumer>
                      {(auth) =>
                        !auth.isInitialized ? (
                          <SplashScreen />
                        ) : (
                          <Routes>
                          {/* <Route index element={<Landing />} /> */}
                          <Route path="/" element={<Auth />}  />
                          {/* <Route path="/logout" element={<LogoutPage />}  /> */}
                          <Route path="/ds" element={
            
                            <RequireAuth>
            
                          <DS />
                          </RequireAuth>
                          }  />
                          
                          {/* <Route
                            path="/connect"
                            element={
                              <RequireAuth>
            
                                <Test />
                              </RequireAuth>
                              
                            }
                    /> */}
                    <Route path="*" element={<NotFound />} />
                      
                        </Routes>
                        )
                      }
                    </AuthConsumer>
    </BrowserRouter>
    </AuthProvider>
  );
}
