import {
  Box,
  Card,
  Container,
  TextField,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { Image } from "mui-image";
import React, { useState, useEffect } from "react";
import { Logo } from "../components/logo";
import { LoadingButton } from "@mui/lab";
import Link from "@mui/material/Link";
import { useAuth } from "../hooks/use-auth";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { List, replace } from "lodash";
import { authApi } from "../api/auth-api";
import Cookies from "js-cookie";
import { apiService } from "../services/api.service";
import config from "../configs";
import moment from "moment";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import FullscreenExitRoundedIcon from "@mui/icons-material/FullscreenExitRounded";
import { AuthContext } from "../contexts/AuthContext";
import ReactPlayer from "react-player";

export const DS = () => {
  let [dateTime, setDateTime] = useState(new Date());
  let [schedule, setSchdule] = useState<any>({});
  let [allSlides, setAllSlides] = useState<any[]>([]);
  let [lastSlideId, setLastSlideId] = useState(0);
  let [slide, setSlide] = useState<any>({ slides: [] });
  var [slideNo, setSlideNo] = useState(-1);
  var [slideMediaType, setSlideMediaType] = useState("image");
  var [slideMediaURL, setSlideMediaURL] = useState("");
  const [showTopBar, setShowTopBar] = useState(true);

  const { isLoggedIn, logout } = React.useContext(AuthContext);

  useEffect(() => {
    var timer = setInterval(() => {
      setDateTime(new Date());
      processSchedule();
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const logoutFunc = async () => {
    try {
      await logout();
    } catch (err) {
      console.log("error in logout");
    }
  };

  const getSlides = async () => {
    await fetch(`${config.apiUrl}/dsms/slide`, {
      method: "GET",
      credentials: "include",
      headers: { "x-preferred-auth": "device" },
    })
      .then((response) => response.json())
      .then((json) => {
        setAllSlides(json);
        // setSlide(json[2]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSchedule = async () => {
    await fetch(`${config.apiUrl}/setting/device`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        if (
          json.length &&
          json[0]?.settingCategory == "DSMS" &&
          json[0]?.settingName == "Schedule" &&
          json[0]?.details
        ) {
          setSchdule(json[0]?.details);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSlides();
    getSchedule();
    setInterval(() => setShowTopBar(false), 10 * 1000);
  }, []);

  useEffect(() => {
    if (slide.slides) {
      setSlideNo(-1);
      nextSlide();
    }
  }, [slide]);

  useEffect(() => {
    console.log(`Current Slide No: ${slideNo}`);

    if (slideNo !== -1) {
      let slideObj = slide.slides[slideNo];

      console.log(slideObj);
      setSlideMediaURL(slideObj.fileURL);
      setSlideMediaType(slideObj.type);
      let duration = parseInt(slideObj.duration, 10) * 1000;

      if (slideObj.type === "image") {
        var timer2 = setInterval(() => {
          nextSlide();
        }, duration);
      }
      return function cleanup() {
        clearInterval(timer2);
      };
    }
  }, [slideNo]);

  const processSchedule = () => {
    if (!Object.keys(schedule).length || !allSlides.length) {
      return;
    }
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let currentDay = weekday[dateTime.getDay()];

    let matchSlideNo = schedule.default.slideId;

    if (schedule.checkSchedule) {
      let daySchedules = schedule.schedule;
      daySchedules.some((obj: any) => {
        if (obj.checked == true && obj.day == currentDay) {
          let times = obj.time;
          times.forEach((obj: any) => {
            const startDT = new Date(
              obj.startTime.replace(
                obj.startTime.substring(0, 10),
                moment().format("YYYY-MM-DD")
              )
            );
            const endDT = new Date(
              obj.endTime.replace(
                obj.endTime.substring(0, 10),
                moment().format("YYYY-MM-DD")
              )
            );

            if (dateTime > startDT && dateTime < endDT) {
              console.log(`Found Match: ${currentDay}`);
              matchSlideNo = obj.slide.slideId;
            }
          });
        }
      });
    }

    if (schedule.checkDates) {
      let dates = schedule.dates;
      dates.forEach((obj: any) => {
        const startDT = new Date(obj.startDate);
        const endDT = new Date(obj.endDate);

        if (dateTime > startDT && dateTime < endDT) {
          console.log(`Found Match: ${startDT}`);
          matchSlideNo = obj.slide.slideId;
        }
      });
    }

    allSlides.forEach((slide) => {
      if (slide.id === matchSlideNo && lastSlideId !== matchSlideNo) {
        setSlide(slide);
        setLastSlideId(matchSlideNo);
      }
    });
  };

  const nextSlide = () => {
    let slides = slide.slides;
    if (slides.length > 0) {
      var nextSlideNo = 0;
      if (slideNo < slides.length - 1) {
        nextSlideNo = slideNo + 1;
      }

      setSlideNo(nextSlideNo);
    }
  };

  const handleMouseMove = (e: any) => {
    if (e.clientY <= 65) {
      setShowTopBar(true);
    } else {
      setShowTopBar(false);
    }
  };

  const toggleFullscreen = () => {
    // https://www.aha.io/engineering/articles/using-the-fullscreen-api-with-react
    if (Boolean(document.fullscreenElement)) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
  };

  return (
    // <Container maxWidth="sm">
    //     <Card sx={{p:5, mt:10,}} variant='elevation'>
    //         <p> Time : {dateTime.toLocaleTimeString()}</p>
    //         <p> Date : {dateTime.toLocaleDateString()}</p>
    //     </Card>
    // </Container>
    <Grid
      container
      sx={{ height: "100vh" }}
      onMouseMove={(e) => handleMouseMove(e)}
    >
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "black",
          backgroundImage: `url(static/images/loader.gif)`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: 'contain',
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#00000090",
            position: "absolute",
            top: 0,
            left: 0,
            height: 65,
            width: "100vw",
            padding: "15px",
            display: showTopBar ? "flex" : "none",
            flexDirection: "row",
            justifyContent: "flex-end",
            spacing: "50px",
          }}
        >
          {/* <IconButton color='error' size='large' aria-label="Fullscreen" onClick={ () => toggleFullscreen() }>
                        {
                            Boolean(document.fullscreenElement)
                            ?
                            <FullscreenExitRoundedIcon fontSize="inherit" />
                            :
                            <FullscreenRoundedIcon fontSize="inherit" />
                        }
                    </IconButton> */}
          <Button
            variant="outlined"
            color="info"
            onClick={() => logoutFunc()}
            // href='/logout'
            style={{ marginLeft: "20px" }}
          >
            Logout
          </Button>
        </Box>
        {/* {slideMediaType === "image" ? (
          <img
            src={slideMediaURL}
            style={{ objectFit: "contain", height: "100vh", width: "100vw" }}
          />
        ) : (
          <video
            preload='auto'
            autoPlay
            loop={slide.slides.length == 1 ? true : false}
            onEnded={nextSlide}
            style={{ objectFit: 'contain', height: '100vh', width: '100vw'}}>
              <source src={slideMediaURL}/>
          </video>
        )} */}

        {slide.slides.map((s: any, index: number) => {
          return s.type === "image" ? (
            <>
              <img
                key={index}
                src={s.fileURL}
                style={{
                  objectFit: "contain",
                  height: "100vh",
                  width: "100vw",
                }}
                hidden={index !== slideNo}
              />
            </>
          ) : (
            // <ReactPlayer
            //   key={index}
            //   playing={index === slideNo}
            //   loop={slide.slides.length == 1 ? true : false}
            //   onEnded={nextSlide}
            //   // url="https://www.youtube.com/watch?v=JcO2vjVBNSc"
            //   url="https://vimeo.com/90509568"
            //   width="100%"
            //   height="100%"
            //   style={{
            //     overflow: "hidden",
            //     display: index === slideNo ? "initial" : "none",
            //   }}
            // />
            <></>
          );
        })}
        {slideMediaType !== "image" ? (
          <ReactPlayer
            playing
            loop={slide.slides.length == 1 ? true : false}
            onEnded={nextSlide}
            url={slideMediaURL}
            width="100%"
            height="100%"
            style={{
              overflow: "hidden",
            }}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
