// AuthContext.tsx
import React, { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/auth-api';
import { apiService } from '../services/api.service';


export interface AuthContextValue  {
  isInitialized: boolean;
  isLoggedIn: boolean;
  isLoading: boolean;
  pauseOperationUrl: string,
  pauseOperationText:string,
  defaultTemplate:{templateId:number, templateImage:string},
  defaultPauseOperationImage: string,
  defaultPauseOperationText: string,
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  reAuth: (token?: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}


export const AuthContext = createContext<AuthContextValue>({
  isInitialized: false,
  isLoggedIn: false,
  isLoading: false,
  pauseOperationUrl: '',
  pauseOperationText:'',
  defaultTemplate:{templateId:1, templateImage:''},
  defaultPauseOperationImage: '',
  defaultPauseOperationText: '',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  reAuth: () => Promise.resolve(),
 
});




export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const {children} = props;

  const [isInitialized, setIsInitialized] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pauseOperationUrl, setPauseOperationUrl] = useState('');
  const [pauseOperationText, setPauseOperationText] = useState('');
  const [template, setTemplate] = useState({templateId:1, templateImage:''});
  const [defaultPauseOperationImage, setDefaultPauseOperationImage] = useState('');
  const [defaultPauseOperationText, setDefaultPauseOperationText] = useState('');



  // const navigate = useNavigate()
  // const location = useLocation();

  useEffect(() => {


    initialize();
  }, []);

  const initialize = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const { success, data } = await authApi.reAuth();
      if(success)
      {
        const {deviceType} = data
        if(deviceType != "Signage-Display")
        {
          console.log('here', deviceType);
          setIsLoggedIn(false)
          setIsInitialized(true)
         
         
        }else{
          const deviceToken =  localStorage.getItem('@token');
    
        if(deviceToken){
     
          setIsLoggedIn(true)
        }
  
        const settings = await apiService.get('/setting/device')
        const moduleSetting = await apiService.get('/setting/device/module')
       
    
        if(settings){
         
          settings.map((settingObj:any)=>{
            if(settingObj.settingName == 'PauseOperationImage')
            {
              setPauseOperationUrl(settingObj.value)
            }
            if(settingObj.settingName == 'PauseOperationText')
            {
              setPauseOperationText(settingObj.value)
            }
            if(settingObj.settingName == 'Template')
            {
              setTemplate(settingObj.details)
            }
          })}
  
          if(moduleSetting){
         
            moduleSetting.map((moduleSettingObj:any)=>{
              if(moduleSettingObj.settingName == 'pauseOperationDefaultText')
              {
                setDefaultPauseOperationText(moduleSettingObj.value)
              }
              if(moduleSettingObj.settingName == 'pauseOperationDefaultImage')
              {
                setDefaultPauseOperationImage(moduleSettingObj.value)
              }
             
            })}
  
        
        setIsLoggedIn(true)
        }

       
        
      }else{
        setIsLoggedIn(false)
      }
      setIsLoading(false)
      setIsInitialized(true)
    } catch (err:any) {
      setIsLoading(false)
      setIsInitialized(true)

      setIsLoggedIn(false)
      if (err.code === 30018) {
        await logout();
        // navigate('/');
        console.log('navigate to home');
        
      }

      console.error(err);
     
    }
};

 



  const login = async (deviceCode: string): Promise<any> => {

    return new Promise(async(resolve, reject) => {
      try {
        const response = await authApi.login({ deviceCode });
  
    console.log('response123', response);
    
    const {deviceUUID, deviceType} = response

    if(deviceType != "Signage-Display")
    {
      console.log('here', deviceType);
      
      reject({"success":false, message:'Invalid Device Code for QMS - TV Display'});
     
    }else{
      const settings = await apiService.get('/setting/device')
      const moduleSetting = await apiService.get('/setting/device/module')
      // console.log('settings', settings);
      localStorage.setItem("@token", deviceUUID);
      localStorage.setItem('@settings', JSON.stringify(settings))
  
      if(settings){
       
        settings.map((settingObj:any)=>{
          if(settingObj.settingName == 'PauseOperationImage')
          {
            setPauseOperationUrl(settingObj.value)
          }
          if(settingObj.settingName == 'PauseOperationText')
          {
            setPauseOperationText(settingObj.value)
          }
          if(settingObj.settingName == 'Template')
          {
            setTemplate(settingObj.details)
          }
        })}
  
        if(moduleSetting){
         
          moduleSetting.map((moduleSettingObj:any)=>{
            if(moduleSettingObj.settingName == 'pauseOperationDefaultText')
            {
              setDefaultPauseOperationText(moduleSettingObj.value)
            }
            if(moduleSettingObj.settingName == 'pauseOperationDefaultImage')
            {
              setDefaultPauseOperationImage(moduleSettingObj.value)
            }
           
          })}
  
  
      setIsLoggedIn(true)
      resolve({"success":true, "message":"Some thing went wrong!"})
    }
   

      } catch (err:any) {
        console.log('err123',err);
        setIsLoggedIn(false)
        console.error('[Auth Api]: ', err);
        reject({"success":false, message:err?.params});
      }
    });

    // try{
  
    // }catch(err){
    //   console.log('err',err);
    //   setIsLoggedIn(false)
      
    // }

  };
  

  const reAuth = async (token?: string): Promise<void> => {
    console.log('in reauth');
    
    // try {
    //   const { success, data } = await authApi.reAuth(token);
    //   console.log('data', data);
    //   console.log('success', success);
      
    //   if (success) {
    //     const user = await authApi.me(data);
    //     setIsLoggedIn(true)
    //     localStorage.setItem("isLoggedId", "true");
    //   } else {
    //    setIsLoggedIn(false)
    //    localStorage.setItem("isLoggedId", "false");
    //   }
    // } catch (err) {
    //   console.error(err);
    //   setIsLoggedIn(false)
    //   localStorage.setItem("isLoggedId", "false");

    // }
  };


  const logout = async (): Promise<void> => {
    try{
    const data:any =  await authApi.logout();
    console.log('data', data);
    if(data?.success)
    {
      // localStorage.removeItem("isLoggedId");
      localStorage.removeItem("@token");
      localStorage.removeItem('@settings')

      setIsLoggedIn(false)
    }
  }catch(err){
    localStorage.removeItem("@token");
    localStorage.removeItem('@settings')

    setIsLoggedIn(false)
    console.log('err',err);
    
  }
  };


 
  return (
    <AuthContext.Provider
      value={{
        isInitialized: isInitialized,
        isLoggedIn: isLoggedIn,
        isLoading:isLoading,
        pauseOperationUrl: pauseOperationUrl,
        pauseOperationText:pauseOperationText,
        defaultTemplate:template,
        defaultPauseOperationImage,
        defaultPauseOperationText,
        login,
        logout,
        reAuth,
       
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;