import { Alert, Box, Card, Container, TextField, Typography } from '@mui/material';
import {  useNavigate } from "react-router-dom";
import React, { useEffect,ClipboardEvent } from 'react';
import { Logo } from '../components/logo';
import { LoadingButton } from '@mui/lab';
import Link from '@mui/material/Link';
import { useAuth } from '../hooks/use-auth';
import { AuthContext } from '../contexts/AuthContext';

function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit"  href="https://swiftbill.co/">
          SwiftBill
        </Link>{' '}
        {new Date().getFullYear()}.
      </Typography>
    );
  }

  
export const Auth=()=> {
  const regex = /^[a-zA-Z0-9]$/;

    const itemsRef = React.useRef<HTMLInputElement[]>([]);
  const { isLoggedIn, defaultTemplate } = React.useContext(AuthContext);

    const [code, setCode] = React.useState(['', '', '', '', '', '']);
    const { login } = useAuth();
    const [loading, setLoading] = React.useState(false);
    const [isInvalid, setIsInvalid] = React.useState<any>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>('');


    let navigate = useNavigate();

    const fetchData = async () => {
        setLoading(true)
        try {
          console.log('deviceCode', code.join(''));
          const data = await login( code.join(''))
          setLoading(false);
          navigate('/ds', { replace: true });
        } catch (error:any) {
            console.log('error',error);
            setLoading(false)
            setErrorMessage(error?.message)
            setIsInvalid(true)
    
        }
      };
 

      const handleKeyDown = (
        event: any,
        index: number
      ): void => {
        console.log('event', event);
    
        if (event.target.value === '') {
          if (index > 0) {
            itemsRef.current[index - 1].focus();
            const newCode = [...code]
            newCode[index] = ''
            setCode(newCode)
          } else {
            const newCode = [...code]
            newCode[index] = ''
            setCode(newCode)
          }
        }
    
        if (regex.test(event.target.value.toUpperCase())) {
    
          const newCode = [...code]
          newCode[index] = event.target.value.toUpperCase()
          console.log('newCode', newCode);
    
          setCode(newCode)
          if (index < 5) {
            itemsRef.current[index + 1].focus();
          }
        }
    
    
      };


      useEffect(() => {

        console.log('isLoggedIn in auth', isLoggedIn);
    
        if (isLoggedIn) {
    
          navigate(`/ds`, { replace: true });
        }
    
      }, [isLoggedIn])

      const handlePaste = (event: ClipboardEvent): void => {
        const paste = event?.clipboardData.getData('text');
        const pasteArray = paste.split('');
        console.log('past', paste);
    
    
        if (pasteArray.length !== 6) {
          return;
        }
    
        let valid = true;
    
        pasteArray.forEach((x) => {
          if (!regex.test(x.toUpperCase())) {
            valid = false;
          }
        });
    
        if (valid) {
          setCode(pasteArray);
          itemsRef.current[5].focus();
        }
      };

      useEffect(() => {
        if (code.filter((c) => c !== '').length === 6) {
          fetchData();
        }

        if (code.filter((c) => c !== '').length === 0) {
          setErrorMessage('')
          setIsInvalid(false)
        }
    
      }, [code.join('')]);

  return (
    <Box
    sx={{ 
      position: 'absolute', 
      top: 0, 
      left: 0, 
      height: '100vh', 
      width: '100vw', 
      backgroundImage: `url(static/images/login-bg.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
    >
    <Container maxWidth="sm">
    <Card sx={{p:5, mt:10,}} variant='elevation'>

    <Box  sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              <Logo></Logo>
      <Typography variant="h5" component="h1" gutterBottom>
        Enter Device Code
      </Typography>
      <Typography variant="subtitle1" color='text.secondary' component="h1" gutterBottom>
        Enter 6 character alphanumeric code to connect this device.
      </Typography>
      {
      isInvalid?
      <Alert style={{ width: '100%' }} severity="error">{errorMessage}</Alert>
      :<></>
      }
      
      <Box
      sx={{
        columnGap: '26px',
        my:5,
       
        flexDirection:'row',
     
        pt: 1,
        
      }}
    >
      {[1, 2, 3, 4, 5, 6].map((ref, index:number) => (
       <> <TextField
          inputRef={(el) => (itemsRef.current[index] = el)}
          // eslint-disable-next-line react/no-array-index-key
          key={`code-${index}`}
          name={`code[${index}]`}
          onChange={(e)=>handleKeyDown(e, index)}
          value={code[index]}
          onPaste={handlePaste}
          sx={{
            display: 'inline-block',
            width:70,
            px:1,
            textAlign: 'center',
            '& .MuiInputBase-input': {
              textAlign: 'center',
            },
          }}
        />

        </>
      ))}
    </Box>
  
      <LoadingButton
        disabled={ code.join('').length != 6  }
        sx={{mb:4,}}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        onClick={()=>fetchData()
        }
      >
        Connect
      </LoadingButton>
      <Copyright />
    </Box>
    </Card>
  </Container>
  </Box>
  )
}
